import React, {useEffect, useState} from "react";
import {SnackbarProvider} from "notistack";
import {Provider, useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";

import "./App.scss";
import {Header} from "./components/Header";
import {LeftSide} from "./components/LeftSide";
import {AnamnezDetailPage} from "./pages/Anamnez/AnamnezDetail";
import {ForgotPage} from "./pages/Forgot";
import {HomePage} from "./pages/Home";
import {LoginPage} from "./pages/Login";
import {NotFoundPage} from "./pages/NotFound";
import {ProfilePage} from "./pages/Profile";
import {ProfileSecurityPage} from "./pages/Profile/ProfileSecurity";
import {RegisterPage} from "./pages/Register";
import TestPage from "./pages/Register/test";
import {VisitDetailPage} from "./pages/VisitDetail";
import {userStatusRoutine} from "./redux/routines/userRoutine";
import store, {persistor} from "./redux/store";
import {KeyboardLangProvider} from "./utils/useKeyboardLang";

const ProtectedRoute = ({user, children}) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppContainer = () => {
  const auth = useSelector((state: any) => state.user.auth);
  const userStatus = useSelector((state: any) => state.user.userStatus);
  const [showHeader, setShowHeader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(userStatusRoutine());
  }, []);

  useEffect(() => {
    if (userStatus.isFailure) {
      navigate("/login", {replace: true});
    }
  }, [userStatus]);

  useEffect(() => {
    if (["/forgot", "/register", "/policy"].indexOf(location.pathname) !== -1) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  }, [navigate]);

  return (
    <>
      {((auth.isSuccess && userStatus.isSuccess) || showHeader) && <Header />}{" "}
      <main>
        {auth.isSuccess && userStatus.isSuccess && <LeftSide />}
        <div className="right-side">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/security"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <ProfileSecurityPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/visits/:id"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <VisitDetailPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/anamnez/:type"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <AnamnezDetailPage />
                </ProtectedRoute>
              }
            />
            <Route path="/forgot" element={<ForgotPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/register2" element={<TestPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </main>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          maxSnack={3}
          hideIconVariant
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <KeyboardLangProvider>
            <AppContainer />
          </KeyboardLangProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
