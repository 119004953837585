import Logo from '../../assets/images/logo.svg';

export const PolicyPage = () => {
  return (
    <div className="container" id="LoginPage">
      <div className="logo">
        <img src={Logo} />
        <p className="logo-name">Иду к врачу</p>
      </div>
    </div>
  );
};
